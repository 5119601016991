import React, { Fragment } from 'react';

import { Action, Actionbar, Paragraph, TextBlock, TextString, Title } from 'ui/components';
import { Fold, Helmet, Layout } from 'ui/partials';

const HomePage = props => (
  <Fragment>
    <Helmet {...props} title="Welcome" />
    <Layout {...props} center>
      <Fold>
        <TextBlock>
          <Title>
            <TextString looks="hcap">Hi, I’m Piotr,</TextString>
            <TextString looks="h1">I help companies deliver delightful digital experiences</TextString>
          </Title>
          <Paragraph looks="p2">
            I’m an interactive producer, product designer and software developer with a broad range of skills and
            expertise in all user experience design related fields.
          </Paragraph>
          <Actionbar>
            <Action button to="/made">
              See my work
            </Action>
            <Action
              button
              email="studio@piotrf.com"
              headers={{
                subject: 'Question from the website',
                body: 'Hi Piotr,',
              }}
              obfuscated
              plain
              text="Get in touch"
            />
          </Actionbar>
        </TextBlock>
      </Fold>
    </Layout>
  </Fragment>
);

export default HomePage;
